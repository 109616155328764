import './App.css';
import React, {useEffect} from "react";
import { Routes, Route } from 'react-router-dom'
import { Home } from './pages/home';
import { OurWork } from './pages/ourWork';
import { Footer } from './components/footer/footer';
import DrawerAppBar from './components/navbar/navtest';
import { Donate } from './pages/donate';
import { About } from './pages/about';
import { Contact } from './pages/contact';
import { Blog } from './pages/blog';
import ReactGA from 'react-ga';
import { TestSubscribe } from './pages/subscribe';
import Loading from './pages/loading';

const TRACKING_ID = "G-K1Q30EHH3M"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <DrawerAppBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/our-Work" element={<OurWork />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path='/test' element={<TestSubscribe />} />
          {/* <Route path='/loading' element={<Loading />} /> */}
        </Routes>
        <Footer />



    </div>
  );
}

export default App;
