import React from "react";
import './contact.css';
import { ContactForm } from '../components/contactForm/contactForm';
import { useTranslation } from 'react-i18next';
import { t, i18n } from 'react-i18next';


export const Contact = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div className='contact-page-container'>
            <h1>{t('contact.h_1')}</h1>
            <p>{t('contact.p_1')}</p>
        <ContactForm />
        </div>
    );
}