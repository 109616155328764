import React, {useState, useRef} from 'react';
import './contactForm.css';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { t, i18n } from 'react-i18next';
import Button from '@mui/material/Button';
import emailjs from 'emailjs-com';
import reCAPTCHA  from "react-google-recaptcha"




export const ContactForm = () => {
    const { t, i18n } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const form = useRef();
    const handleSubmit = () => {
        console.log(name, email, message);
    }

    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
    
        emailjs.sendForm('service_jwkaxhp', 'template_8jappr9', form.current, 'iHxcs62hBdAJduz7u')
          .then((result) => {
              window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
          }, (error) => {
              console.log(error.text);
          });
      }


    return (
        <div className='contact-form-container'>
              <form ref={form} onSubmit={sendEmail}>
            <TextField
                id="outlined-basic"
                label="Name"
                name='user_name'
                className='contact-form-filed'
            />
            <TextField
                id="outlined-basic"
                label="Email"
                name='user_email'
                className='contact-form-filed'

            />
            <TextField
                id="outlined-multiline-static"
                label="Message"
                multiline rows={4}
                name='message'
                className='contact-form-filed'

            />
            <Button variant="contained" color="primary" type='submit' value='send'>
              Send
            </Button>
            {/* <input type="submit" value="Send" /> */}
            </form>
            
        </div>
    );
}