import React, {useState} from 'react';
import './donationCards.css';
import useDonationStore from '../../store/donationStore.js';
import FadeIn from 'react-fade-in';


export const DonationCards = ({donationAmount}) => {

    // const [color, setcolor] = useState('blueviolet')
    const don = useDonationStore((state) => state.donationAmount)

    function clickHanlder() {
        useDonationStore.setState({donationAmount: donationAmount})
        // setcolor('red')
        // alert(`Du har valgt å donere ${don} kr`)
        // change the color on click
        // change the color of donation-cards-container on click
    }

    return (
        <FadeIn delay={250}>
        <div className='donation-cards-container' onClick={() => clickHanlder()} style={{backgroundColor : don != donationAmount ? 'grey':'#9c27b0'}}>
            <h1>{donationAmount}</h1>
            </div>
        </FadeIn>
    );
};
