import React from 'react';
import './ourwork.css'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { t, i18n} from 'react-i18next';
import { useTranslation } from 'react-i18next';


export const OurWork = () => {

    const { t, i18n } = useTranslation();

    return (
        <div>
        <div className='our-work-container'>

        <h1>{t('our-work.h_1')}</h1>
        <p>{t('our-work.p_1')}</p>        
        <h1>{t('our-work.h_2')}</h1>
        <p>{t('our-work.p_2')}</p>
        <h1>{t('our-work.h_3')}</h1>
        <p>{t('our-work.p_3')}</p>        
        <h1>{t('our-work.h_4')}</h1>
        <p>{t('our-work.p_4')}</p>
        <Link to='/donate' className='donate-button'>
        <Button variant="contained" color="secondary" >{t('our-work.button')}
          
        </Button>
        </Link>

        </div>
        </div>
    );
}
