import React,{useState} from 'react'
import { useSpring, animated } from '@react-spring/web'
import { Waypoint } from "react-waypoint";


export default function Fade_in ({children}) {
    const [inView, setInview] = useState(false);

    const transition = useSpring({
      delay: 500,
      friction: 500,
      mass: 50,
        tension: 500,
      from : {
        x: !inView ? 0 : -500,
        opacity: !inView ? 1 : 0,
        },
      to: {
        x: 0,
        y: !inView ? 12 : 0,
        opacity: !inView ? 0 : 1,
      },
    });

  return (
        <Waypoint onEnter={() => setInview(true)}>
        <animated.div style={transition}>
        {children}
        </animated.div>
        </Waypoint>
  )
}
