import React from 'react';
import './history.css'
import { useTranslation } from 'react-i18next';
import { t, i18n } from 'react-i18next';
import { Link } from 'react-router-dom';

export const History = () => {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className='history-container'>
                <div className='history-image'>
                    <img src='https://gfx.nrk.no/737boMhDRHjNypksZShneA8GOCX8K-1tzi5BFOqcpTYw.jpg' alt='history' />
                </div>
                    <div className='history-image-text'>
                        <p>{t('history.p_1')}</p>
                        <Link to='/about' className='history-link'>
                            {t('history.link')}
                            </Link>
                </div>

            </div>
        </div>
    );
}