import React from 'react';
import { Header } from '../components/header/header';
import { People } from '../components/people/people';
import { SocialMediaPosts } from '../components/socialMediaPosts/socialMediaPosts';
import { Partners } from '../components/partners/partners';
import { OurSupport } from '../components/our_support/ourSupport';
import { History } from '../components/history/history';
import { Gallery } from '../components/gallery/gallery'
import FadeIn from 'react-fade-in';


export const Home = () => {
    return (
        <div>
        <FadeIn delay={250}>
        <Header />
        </FadeIn>
        <History />
        <Gallery />
        {/* <SocialMediaPosts /> */}
        {/* <People /> */}
        {/* <Partners /> */}
        {/* <OurSupport /> */}
        </div>
    );
}

