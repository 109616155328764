import React from 'react';
import './blog.css';



export const Blog = () => {
    return (
        <div>
            <h1>Her vil bloggen være</h1>
        </div>
    );
}