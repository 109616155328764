import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useTranslation } from 'react-i18next'



export default function BasicSelect() {
  const [lang, setLang] = React.useState('fi fi-no');
  const { t, i18n } = useTranslation()
  
  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang)
  }


  const handleChange = (event) => {
    setLang(event.target.value);
    changeLanguageHandler(event.target.value)   
  }




  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl >
        <InputLabel id="demo-simple-select-label">{<span class={lang}></span>}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
        //   value={lang}
            label="Language"
          onChange={handleChange}
>
          <MenuItem value={'no'}>{<span class='fi fi-no'> </span>} No </MenuItem>
          <MenuItem value={'gb'}>{<span class='fi fi-gb'> </span>} En </MenuItem>
          <MenuItem value={'rn'}>{<span class='fi fi-bi'> </span>} RN </MenuItem>
          <MenuItem value={'sw'}>{<span class='fi fi-bi'> </span>} SW </MenuItem>
          <MenuItem value={'fr'}>{<span class='fi fi-fr'> </span>} FR </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}