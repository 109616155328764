import React, {useRef, useState} from 'react'
import './gallery.css'

import Fade_in from '../../helpers/fade_in'

import image_1 from '../../images/image_1.jpg'
import image_2 from '../../images/image_2.jpg'
import image_3 from '../../images/image_3.jpg'
import image_4 from '../../images/image_4.jpg'
import Button from '@mui/material/Button'



const Content = ({image, header,  text}) => {
    return (
        <div className='gallery-container'>
        <div className='gallery-content_1'>
            <div className='gallery-image_1'>
                <img src={image} alt='image' />
            </div>
            <div className='gallery-text_1'>
                <h1>{header}</h1>
                <p>{text}</p>
            </div>
        </div>
        </div>
    )
}



export const Gallery = ()  => {
    
   
    
   
  return (
    <>
    <Fade_in>
    <Content image={image_1} header='Møt kvinnene i systua' text="In hac habitasse platea dictumst. Aliquam eget lorem et nulla sollicitudin ultricies ut ac nulla. Mauris dolor lacus, eleifend nec feugiat et, finibus non tortor. Integer ornare magna sit amet tortor feugiat viverra. Nunc pharetra ac purus vitae volutpat. Quisque id imperdiet libero, vitae maximus orci. Ut dapibus scelerisque nulla non laoreet. Proin sit amet ligula enim. Duis lobortis risus ut auctor tincidunt. Aenean blandit tortor non lacus blandit, quis convallis lectus vehicula. Phasellus eget tortor ut nisl ultricies vulputate congue eget tellus. Proin ullamcorper efficitur ante ut fringilla." />
    </Fade_in>
    <Fade_in>
    <Content image={image_2} header='Hello wolrd' text="In hac habitasse platea dictumst. Aliquam eget lorem et nulla sollicitudin ultricies ut ac nulla. Mauris dolor lacus, eleifend nec feugiat et, finibus non tortor. Integer ornare magna sit amet tortor feugiat viverra. Nunc pharetra ac purus vitae volutpat. Quisque id imperdiet libero, vitae maximus orci. Ut dapibus scelerisque nulla non laoreet. Proin sit amet ligula enim. Duis lobortis risus ut auctor tincidunt. Aenean blandit tortor non lacus blandit, quis convallis lectus vehicula. Phasellus eget tortor ut nisl ultricies vulputate congue eget tellus. Proin ullamcorper efficitur ante ut fringilla." />
    </Fade_in>
    <Fade_in>
    <Content image={image_3} header='Title 3' text="In hac habitasse platea dictumst. Aliquam eget lorem et nulla sollicitudin ultricies ut ac nulla. Mauris dolor lacus, eleifend nec feugiat et, finibus non tortor. Integer ornare magna sit amet tortor feugiat viverra. Nunc pharetra ac purus vitae volutpat. Quisque id imperdiet libero, vitae maximus orci. Ut dapibus scelerisque nulla non laoreet. Proin sit amet ligula enim. Duis lobortis risus ut auctor tincidunt. Aenean blandit tortor non lacus blandit, quis convallis lectus vehicula. Phasellus eget tortor ut nisl ultricies vulputate congue eget tellus. Proin ullamcorper efficitur ante ut fringilla." />
    </Fade_in>
    <Fade_in>
    <Content image={image_4} header='Title 4' text="In hac habitasse platea dictumst. Aliquam eget lorem et nulla sollicitudin ultricies ut ac nulla. Mauris dolor lacus, eleifend nec feugiat et, finibus non tortor. Integer ornare magna sit amet tortor feugiat viverra. Nunc pharetra ac purus vitae volutpat. Quisque id imperdiet libero, vitae maximus orci. Ut dapibus scelerisque nulla non laoreet. Proin sit amet ligula enim. Duis lobortis risus ut auctor tincidunt. Aenean blandit tortor non lacus blandit, quis convallis lectus vehicula. Phasellus eget tortor ut nisl ultricies vulputate congue eget tellus. Proin ullamcorper efficitur ante ut fringilla." />
    </Fade_in>
    </>
  )
}

