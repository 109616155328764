import React from "react";
import './footer.css'
import Container from '@mui/material/Container'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YoutubeIcon from '@mui/icons-material/YouTube';
import { fontSize } from "@mui/system";
import { useTranslation } from 'react-i18next';
import { t, i18n } from 'react-i18next';

export const Footer = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div>
          <div className='footer-container'>
          <div className='social-container'>
                <h1>{t('footer.h_1')}</h1>
                <div className='social-icons'>
                <FacebookIcon fontSize="large" onClick={() => window.open('https://www.facebook.com/groups/409182266375664', '_blank')}/>
                <InstagramIcon fontSize="large" onClick={() => window.open('https://www.instagram.com/aishabigset/?hl=en', '_blank')}/>
                <YoutubeIcon fontSize="large" onClick={() => window.open('https://www.youtube.com/@aishabigset/videos', '_blank')}/>
                    </div>
                    </div>
            <div className='contact-container'>
                <h1>{t('footer.h_2')}</h1>
                <h1>{t('footer.h_3')}</h1>
                <h1>{t('footer.h_4')}</h1>
                <h1>{t('footer.h_5')}</h1>
                <h1>{t('footer.h_6')}</h1>

                </div>
                
            </div>
        </div>
    );
    }
