import React from 'react';
import './header.css'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'

import {t, i18n} from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const Header = () => {

  const navigate = useNavigate();

  const handleNavClick = (page) => {
    // alert(`You clicked ${page}!`)
    navigate(`${page.toLowerCase()}`);
  };

    const { t, i18n } = useTranslation();
    let image_link = 'https://images.pexels.com/photos/13415997/pexels-photo-13415997.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'    
    return (
        <div className='header-container'>
        <Container maxWidth="xs">
        <div className='header-text'>
        <h1>{t('header.h_1')}</h1>
        <p>{t('header.p_1')}</p>
        <div className='header-button'>
        <Link to='/our-work'>
          {t('header.button_1')}
        </Link>
          </div>
        </div>
        </Container>

        <Container maxWidth="xs">
            <div className='donate-container'>
            <h1>{t('header.h_2')}</h1>
            <Button variant="contained" color="secondary" onClick={() => handleNavClick('/donate')}>
              {t('header.button_2')}
            </Button>
            </div>
          
        </Container>

        
        </div>
    );
}
