import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import './about.css';

export const About = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div className='about-container'>
            <h1>{t('about.h_1')}</h1>
            <p>{t('about.p_1')}</p>
            <p>{t('about.p_2')}</p>
            <p>{t('about.p_3')}</p>
            <p>{t('about.p_4')}</p>
        </div>
    );
}