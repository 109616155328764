import React, {useEffect, useState} from "react";
import './donate.css'
import { DonationCards } from "../components/donationCards/donationCards";

import useDonationStore from '../store/donationStore.js'

// import {useDonationStore }  from '../components/donationCards/donationCards'
import { t, i18n} from 'react-i18next';
import { useTranslation } from 'react-i18next';



import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FadeIn from 'react-fade-in';


// This values are the props in the UI
// const don = "2";
// const currency = "NOK";
const style = {"layout":"vertical"};

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ currency, showSpinner }) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const don = useDonationStore((state) => state.donationAmount)
    const { t, i18n } = useTranslation();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
                
            },
        });
    }, [currency, showSpinner]);

 

    return (<>

            { (showSpinner && isPending) && <div className="spinner" /> }
            {don === 0 ? <p>{t ('donate.p_2')}</p> : 
            <div className='paypal-buttons'>
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[don, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: don,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            console.log('Creating order', orderId)
                            return orderId;
                        });
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function () {
                        // Your code here after capture the order
                        console.log('Order captured', data)
                    });
                }}
            />
            </div>
            }
        </>
    );
}


const SubscriptionButtonWrapper = ({ type }) => {
    const [{ options }, dispatch] = usePayPalScriptReducer();
    const don = useDonationStore((state) => state.donationAmount)
    const [ productId, setProductID ] = useState('')
    const { t, i18n } = useTranslation();

    function handlePlan () {
        if (don === 100 ){
            setProductID('P-8D409070PJ6323253MR2N4JY')
        }        
    }

	useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
        handlePlan()
    }, [type, don]);


    return (<>
    { don === 0 ? <p>{t ('donate.p_2')}</p> :
    <div classname='paypal-buttons'>
    <PayPalButtons
		createSubscription={(data, actions) => {
			return actions.subscription
				.create({
					plan_id: productId,
				})
				.then((orderId) => {
					// Your code here after create the order
					return orderId;
				});
		}}
		style={{
			label: "subscribe",
		}}
	/> 
    </div>
    }
   </> );
}


const FastGiverButton = () => {
    return (
        <>
        <div className='fast-giver-container'>
            <h1>Bli fast giver</h1>
        </div>
        </>
    )
}

const EngangsDonasjon = () => {
    return (
        <>
        <div className='engangs-donasjon-container'>
            <h1>Donasjon</h1>
        </div>
        </>
        )
}



export const Donate = () => {
    const { t, i18n } = useTranslation();

    const [checked, setChecked] = useState(false)
    const [donnationType, setDonnationType] = useState('')  

    const handleEngang = () => {
        setDonnationType('engang')
        // alert('hello')

    }

    const handleFast = () => {
        setDonnationType('fast')
        // alert('hello')
    }

    
    const handleCheck = () => {
        setChecked(!checked)
    }
    return (
        <FadeIn>
        <div className="donate-page-container">
        <h1>{t ('donate.h_1')}</h1>
        <p>{t ('donate.p_1')}</p>
        <p></p>

        <div className='donation-type-container'>
        <div className='fast' onClick={handleFast}>
        <FastGiverButton />
        </div>
        <div className='engang' onClick={handleEngang}>
        <EngangsDonasjon />
        </div>
        </div>
        {donnationType === 'engang' ? 
        <>
            <div className='donate-page-cards-container'>
            <DonationCards donationAmount={100}/>
            <DonationCards donationAmount={200}/>
            <DonationCards donationAmount={350}/>
            <DonationCards donationAmount={500}/>
            
        </div>
        <PayPalScriptProvider
                options={{
                    "client-id": "AQ0xS2r-0UKztGEQlQ8JWPiKNHrIkYpb44xfnOWJlbbK8avM7JQfhAPlkt_Jl_cMf0SHl0orNdxeqA8c",
                    components: "buttons",
                    currency: "NOK"
                }}
            >

                <ButtonWrapper
                    currency={"NOK"}
                />
            </PayPalScriptProvider>
            </>
            : null }

            {donnationType === 'fast' ?
            <>
            <div className='donate-page-cards-container'>
            <DonationCards donationAmount={100}/>
            <DonationCards donationAmount={200}/>
            <DonationCards donationAmount={350}/>
            <DonationCards donationAmount={500}/>
            
        </div>
            <PayPalScriptProvider
			options={{
				"client-id": "AQ0xS2r-0UKztGEQlQ8JWPiKNHrIkYpb44xfnOWJlbbK8avM7JQfhAPlkt_Jl_cMf0SHl0orNdxeqA8c",
				components: "buttons",
				intent: "subscription",
				vault: true,
			}}
		>
			<SubscriptionButtonWrapper type='subscription'/>
		</PayPalScriptProvider>
      
            </>
            : null }
            

        </div>
        </FadeIn>
    );
}
